import React, { lazy, useEffect, useState } from 'react'
import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate, Outlet } from 'react-router-dom'
import { themeChange } from 'theme-change'
import { checkAuth, checkSession, logout, testSync } from './app/auth';
import { fbAuth } from "./app/firebase";
import initializeApp from './app/init';
import { Toaster } from 'react-hot-toast';
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from 'react-redux';
import { selectFirestoreUser } from './features/auth/authSlice';
import { onAuthStateChanged } from 'firebase/auth';
import Loader from "./components/Loader/Loader"

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

// Initializing different libraries
initializeApp();

// testSync();

function App() {
  const [currentUid, setCurrentUid] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false);
  const [needDefaultCompany, setNeedDefaultCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [status, setStatus] = useState(200);
  const firestoreUser = useSelector(selectFirestoreUser);
  const navigate = useNavigate();

  const onIdle = async () => {
    setCurrentUid(null);
    setLoggedOut(true);
    await logout();
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    // onActive,
    // onAction,
    timeout: 1000 * 60 * 60 * 12, /* 12 hr */
    throttle: 500
  })

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
    
    const unsub = onAuthStateChanged(fbAuth, async (user) => {
      setCurrentUid(user ? user.uid : null);
      if (!user) {
        console.log('INIT - no user yet -> /login');
        navigate('/login');
      }
      else {
        const validSession = await checkSession();
        console.log('valid session?', validSession);
      }
      setIsLoading(false);
    });
    
    if (currentUid) {
      return () => {
        unsub();
      };
    }

  }, []);

  useEffect(() => {
    if (currentUid && firestoreUser) {
      // Check if the user is an affiliate and if additional conditions are met
      const shouldRedirectToSettings = firestoreUser?.role?.affiliate === true &&
        (firestoreUser.defaultCompanyId === '' || 
          firestoreUser.defaultCompanyId === undefined ||
          firestoreUser.defaultCompanyId === 0);

      const path = shouldRedirectToSettings ? '/app/settings-profile' : '/app/active-visits';
      navigate(path);
    }
    else {
      navigate('/login');
    }
  }, [currentUid, firestoreUser]);

  if (error.length) return (<><div>Error: <span>{error}</span></div></>);
  if (isLoading) return (<>Loading... <Loader /></>);

  return (
    <>
      <Outlet />
    </>
  )
}

export default App
