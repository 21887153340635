import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux'
import SuspenseContent from './containers/SuspenseContent';
import { initializeAuthListener } from './features/auth/authSlice';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Toaster } from 'react-hot-toast';

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

const root = ReactDOM.createRoot(document.getElementById('root'));

store.dispatch(initializeAuthListener());

root.render(
  // <React.StrictMode>
  <Suspense fallback={<SuspenseContent />}>
    <Provider store={store}>      
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="app/*" element={<Layout />} />
            <Route path="*" element={<Navigate to="login" replace={true} />} />}
          </Route>
        </Routes>
      </Router>
      <Toaster position="top-center" />
    </Provider>
  </Suspense>
  // </React.StrictMode>
);