import {initializeApp} from "firebase/app";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {connectStorageEmulator, getStorage} from "firebase/storage";
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {connectFunctionsEmulator, getFunctions, httpsCallable} from "firebase/functions";
import {firebaseConfig} from "../config/firebaseConfig.js";

export const fbApp = initializeApp(firebaseConfig);
export const fbDb = getFirestore(fbApp);
export const fbStorage = getStorage(fbApp);
export const fbAuth = getAuth(fbApp);
export const fbFunctions = getFunctions();

// Connect to Emulators
if (process.env.PUBLIC_URL === "http://127.0.0.1:5005") {
  connectAuthEmulator(getAuth(), "http://127.0.0.1:9099", { disableWarnings: false });
  connectFunctionsEmulator(fbFunctions, "127.0.0.1", 5004);
  connectStorageEmulator(fbStorage, "127.0.0.1", 9199);
  connectFirestoreEmulator(fbDb, '127.0.0.1', 8080);
}

export const fbSignIn = httpsCallable(fbFunctions, 'signIn');
export const fbSetupUser = httpsCallable(fbFunctions, 'setupUser');
export const fbSyncContactToUser = httpsCallable(fbFunctions, 'syncContactToUser');
export const fbGetLoggedInUser = httpsCallable(fbFunctions, 'getLoggedInUser');
export const fbGetUserRole = httpsCallable(fbFunctions, 'getUserRole');
export const fbUserIsAffiliate = httpsCallable(fbFunctions, 'userIsAffiliate');
export const fbUserIsAdmin = httpsCallable(fbFunctions, 'userIsAdmin');
export const fbUserIsPhysician = httpsCallable(fbFunctions, 'userIsPhysician');
export const fbExpireSession = httpsCallable(fbFunctions, 'expireSession');
export const fbLog = httpsCallable(fbFunctions, 'log');
export const fbSync = httpsCallable(fbFunctions, 'syncHubspotContactsToFirebase');
export const fbAreThereActiveStaffUsers = httpsCallable(fbFunctions, 'areThereActiveStaffUsers');
